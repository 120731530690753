
import { defineComponent } from 'vue'
import { useCarWash } from '@/composables/useCarWash'
import { useRouter } from 'vue-router'
export default defineComponent({
    name: 'CarWashList',
    setup() {
        const { isLoading, carWashList, fetchCarWashList } = useCarWash()

        fetchCarWashList()
        const router = useRouter()
        return {
            isLoading,
            router,
            carWashList,
        }
    },
})
