import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "m-main" }
const _hoisted_2 = {
  key: 1,
  class: "a-section"
}
const _hoisted_3 = { class: "m-card" }
const _hoisted_4 = { class: "m-card__list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "m-card__column m-card__column--flex2" }
const _hoisted_7 = { class: "a-title" }
const _hoisted_8 = { class: "a-text" }
const _hoisted_9 = { class: "m-card__column m-card__column--flex2 m-card__column--flexEnd" }
const _hoisted_10 = { class: "m-card__alerts" }
const _hoisted_11 = {
  key: 0,
  class: "m-card__alert"
}
const _hoisted_12 = {
  key: 1,
  class: "m-card__alert"
}
const _hoisted_13 = { class: "m-card__column m-card__column--last m-card__column--flexEnd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_loader = _resolveComponent("a-loader")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_a_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carWashList, (carWash) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: carWash.id,
                  class: "m-card__item m-card__item--borderBottom",
                  onClick: ($event: any) => (_ctx.router.push({ name: 'CarWashDetails', params: { id: carWash.id } }))
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(carWash.warehouseDetail.city), 1),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(carWash.warehouseDetail.address) + " " + _toDisplayString(carWash.warehouseDetail.address2), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (carWash.refill)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_a_icon, { icon: "Refill" })
                          ]))
                        : _createCommentVNode("", true),
                      (carWash.deliveryToConfirm)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_a_icon, { icon: "Question" })
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_a_icon, {
                      icon: "ArrowRight",
                      clickable: true
                    })
                  ])
                ], 8, _hoisted_5))
              }), 128))
            ])
          ])
        ]))
  ]))
}